import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { HeroImageChildren } from '/src/components/layout/HeroImage'
import { AsyncWrapper, LinkedList } from '/src/components/common'
import { useCatalogues } from '/src/hooks'

const items = [
  {
    path: 'https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/eofy-Sale-VIC-LR.pdf',
    content: <>
      <StaticImage
        src="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/EOFYS-web-banner-Icon.jpg"
        alt="Metalcorp End of Financial Year 2024 VIC"
        width={320}
        height={250}
        placeholder="blurred"
        layout="fixed" />
      <h3 className="mt-3">Metalcorp End of Financial Year Sale 2024 VIC</h3>
    </>
  },
  {
    path: 'https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/eofy-Sale-NSW-LR.pdf',
    content: <>
      <StaticImage
        src="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/EOFYS-web-banner-Icon.jpg"
        alt="Metalcorp End of Financial Year 2024 NSW"
        width={320}
        height={250}
        placeholder="blurred"
        layout="fixed" />
      <h3 className="mt-3">Metalcorp End of Financial Year 2024 NSW</h3>
    </>
  },
  {
    path: 'https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/eofy-Sale-QLD-LR.pdf',
    content: <>
      <StaticImage
        src="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/EOFYS-web-banner-Icon.jpg"
        alt="Metalcorp End of Financial Year 2024 QLD"
        width={320}
        height={250}
        placeholder="blurred"
        layout="fixed" />
      <h3 className="mt-3">Metalcorp End of Financial Year 2024 QLD</h3>
    </>
  },
  {
    path: 'https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/eofy-Sale-VIC-LR.pdf',
    content: <>
      <StaticImage
        src="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/EOFYS-web-banner-Icon.jpg"
        alt="Metalcorp End of Financial Year 2024 SA"
        width={320}
        height={250}
        placeholder="blurred"
        layout="fixed" />
      <h3 className="mt-3">Metalcorp End of Financial Year 2024 SA</h3>
    </>
  },
  {
    path: 'https://metalcorp-drupal-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/2024-01/MC-National-Product-Catalogue-LR.pdf',
    content: <>
      <h3><FontAwesomeIcon icon={faClipboard} /> Metalcorp National Product Catalogue 2023</h3>
    </>
  },
  {
    path: 'https://metalcorp-drupal-assets.s3.ap-southeast-2.amazonaws.com/s3fs-public/2024-01/Orrcon-National-Product-Catalogue-2023.pdf',
    content: <>
      <h3><FontAwesomeIcon icon={faClipboard} /> Orrcon Steel National Product Catalogue 2023</h3>
    </>
  }
]

const CatalogueLinkedList = () => {
  const data = useCatalogues()

  return (
    <LinkedList items={data} />
  )
}

const CataloguePage = () => {
  return (
    <Layout hero={<HeroImageChildren title="CATALOGUES & BROCHURES">
      <StaticImage
        src="../../images/SpringSale-Banner.jpg"
        alt="Hero image"
        width={1420}
        height={250}
        placeholder="blurred"
        layout="fixed" />
    </HeroImageChildren>} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row mt-4">
        <Col sm={10}>
          <p>
            With huge stock holdings, our products can be cut to length and delivered to site. 
            Click on a link below to view relevant catalogues.
            Alternatively, call 1300 306 204 for a hard copy to be mailed to you.
          </p>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <AsyncWrapper>
            <CatalogueLinkedList />
          </AsyncWrapper>
        </Col>
      </Row>
    </Layout>
  )
}

export default CataloguePage

export const Head = () => {
  return (
    <>
      <title>Catalogues & Borchures | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}
